@import "../../colors";

.dialog {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 240px;
    height: 240px;
    padding: 40px;
    border: 2px solid $border;
    border-radius: 16px;
  }

  .description {
    margin: 0;
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: $black;
  }

  .name {
    margin-top: 32px;
    margin-bottom: 16px;
    padding: 5px 12px;
    border-radius: 16px;
    color: $white;
    background: $green;

    font-family: Nunito, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;

    word-break: break-word;
  }

  .share {
    margin-top: 32px;
    margin-bottom: 16px;
    font-family: Inter, serif;
    font-size: 12px;
    line-height: 150%;
    color: $grey;
  }

  .shareButtons {
    display: flex;
    & > * + * { margin-left: 8px; } // gap not work in safari

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      border: none;
      cursor: pointer;

      .buttonIcon {
        max-width: 24px;
        max-height: 24px;
      }
    }

    .telegramButton {
      background: $telegramButton !important;
    }
    .vkButton {
      background: $vkButton !important;
    }
  }
}
