@import "../../colors";

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box {
  position: relative;
  width: 40px;
  height: 40px;

  &:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    margin-left: -50%;
    border-radius: 500rem;
    border: 2px solid $border;
  }

  &:after {
    position: absolute;
    left: 50%;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    margin-left: -50%;
    border-radius: 500rem;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent;
    border-color: $black transparent transparent;
    animation: spin-animation infinite 1s linear;
  }
}
