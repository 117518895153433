@import "./Common/colors";

html, body {
  background: url("Common/Images/Papyrus.png"), #FAF5EF;
  background-blend-mode: luminosity, normal;
}

body {
  margin: 0;
  font-family: Inter, serif;
}

input, textarea, select {
  font-family: inherit;
}

.scrollbar-track {
  z-index: auto !important;
}

input, textarea, button {
  font-size: 14px;
}

.flicking-pagination-bullet {
  background-color: $primaryDark;
  opacity: .3;
}

.flicking-pagination-bullet-active {
  opacity: 1;
}
