@import "../../colors";
@import "../../const";

.header {
  display: flex;
  justify-content: space-between;
  height: $headerHeight;
  padding: 0 32px;

  .logo {
    padding: 10px 24px 18px;
    background: $white;
    box-shadow: 0 0 8px $shadow;
    border-radius: 0 0 16px 16px;
  }

  .options {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background: $white;
    box-shadow: 0 0 8px $shadow;
    border-radius: 0 0 16px 16px;
    & > * + * { margin-left: 16px; } // gap not work in safari

    .icon {
      max-height: 20px;
      max-width: 20px;
    }

    .telegramButton {
      border: none;
      background: $telegramButton;
    }
    .googleButton {
      border: none;
      background: $googleButton;
    }

    .languageSelector {
      width: 100px;
      padding: 10px 20px 10px 10px;
      background: $white;
      border: 1px solid $shadow;
      box-sizing: border-box;
      border-radius: 10px;

      font-family: Nunito, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: $primaryDark;

      appearance: none;
      background-image: url("./Bottom.svg");
      background-position: right 10px center;
      background-repeat: no-repeat;
    }
  }
}

.popup {
  display: flex;
  flex-direction: column;
  & > * + * { margin-top: 10px; } // gap not work in safari
  padding: 10px;
  z-index: 10;

  background: $white;
  box-shadow: 0 2px 7px $shadow;
  border-radius: 8px;

  &.hidden {
    display: none;
  }
}

.popup[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.popup[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.popup[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

.popup[data-popper-placement^='right'] > .arrow {
  left: -4px;
}
