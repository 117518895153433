@import "../../colors";

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > * + * { margin-top: 4px; } // gap not work in safari
  padding: 10px;

  position: relative;
  width: 180px;
  min-width: 180px;
  height: 170px;
  background: $white;
  box-sizing: border-box;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  transform: translate(0, 0);
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  &.transparency {
    opacity: 0;
  }

  .icon {
    width: 60px;
    height: 60px;
    border: none;
  }

  .name {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $black;
  }

  .deadline {
    display: flex;
    & > * + * { margin-left: 4px; } // gap not work in safari
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: $grey;
  }

  .spaceName {
    position: relative;
    padding: 2px 10px 3px;

    font-family: Nunito, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;

    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    opacity: 0.8;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0.1;
      border-radius: 16px;
    }
  }
}
