@import "../../colors";

.icon {
  background: #FFFFFF;
  border: 1px solid $border;
  box-sizing: border-box;
  border-radius: 8px;

  &.select {
    outline: 2px solid $primaryDark;
  }
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 80%;
    height: 80%;
  }

  .errorImage {
    width: 100%;
    height: 100%;
  }
}
