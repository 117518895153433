@import "../../colors";

.box {
  position: fixed;
  bottom: 32px;
  left: 32px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  z-index: 1;

  .toast {
    position: relative;

    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    background-color: $primaryLight;
    color: $primaryDark;
    border-radius: 10px;
    border: 1px solid $primaryDark;
    padding: 7px 30px 7px 10px;
    width: 215px;
    word-break: break-word;

    .close {
      position: absolute;
      right: 8px;
      top: 8px;
      cursor: pointer;

      path {
        stroke: $primaryDark;
      }
    }

    &.primary {
      background-color: $primaryDark;
      color: $primaryLight;

      .close path {
        stroke: $primaryLight;
      }
    }

    &.success {
      border-color: $green;
      background-color: $greenLight;
      color: $green;

      .close path {
        stroke: $green;
      }
    }

    &.warning {
      border-color: $warning;
      background-color: $warningLight;
      color: $warning;

      .close path {
        stroke: $warning;
      }
    }

    &.danger {
      border-color: $error;
      background-color: $errorLight;
      color: $error;

      .close path {
        stroke: $error;
      }
    }
  }
}
